<template>
  <div class="testing__comment block__content">
    <div class="testing__comment-title mb-5">Комментарий</div>
    <div class="testing__comment-text">
      {{ test.Description }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    comment: {
      type: String,
      default: "",
    },
    test: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
};
</script>
<style lang="scss">
.testing {
  &__comment {
    width: 446px;
    min-height: 186px;
    padding: 20px 31px;
    &-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: rgb(33, 33, 33);
    }
    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: rgb(33, 33, 33);
    }
  }
}
</style>
